@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --grey: #5b5b5b;
    --green-primary: #22b41f;
    --green-nuke: #00ff57;
    --black: #151515;
    --app-border: 10px;
    --blue: #3151de;
}

@font-face {
    font-family: "Inter";
    font-weight: 300;
    src: url(./fonts/Inter-Light.ttf) format("woff");
}
@font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url(./fonts/Inter-Regular.ttf) format("woff");
}
@font-face {
    font-family: "Inter";
    font-weight: 500;
    src: url(./fonts/Inter-Medium.ttf) format("woff");
}
@font-face {
    font-family: "Inter";
    font-weight: 600;
    src: url(./fonts/Inter-SemiBold.ttf) format("woff");
}
@font-face {
    font-family: "Inter";
    font-weight: 700;
    src: url(./fonts/Inter-Bold.ttf) format("woff");
}
@font-face {
    font-family: "Inter";
    font-weight: 800;
    src: url(./fonts/Inter-Black.ttf) format("woff");
}
@font-face {
    font-family: "Inter";
    font-weight: 900;
    src: url(./fonts/Inter-ExtraBold.ttf) format("woff");
}
@font-face {
    font-family: "Barlow";
    font-weight: 300;
    src: url(./fonts/Barlow-Thin.ttf) format("woff");
}

@media (prefers-color-scheme: dark) {
    :root {
        --grey: #5b5b5b;
        --green-primary: #22b41f;
        --green-nuke: #00ff57;
        --black: #151515;
        --app-border: 10px;
    }
}

.barlow {
    font-family: Barlow !important;
}

html {
    scroll-behavior: smooth !important;
}

body {
    background: linear-gradient(#d9d9d9, #eeeeee);
    min-height: 100dvh;
    font-family: Inter;
}
.border-radius {
    border-radius: var(--app-border);
}

.text-nuke {
    color: var(--green-nuke) !important;
}

.text-primary {
    color: var(--green-primary);
}
.bg-primary {
    background: var(--green-primary);
}

.black-bg {
    background-color: #151515;
}

button,
input[type="submit"],
input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

/* https://uiverse.io/ShrinilDhorda/wicked-lionfish-83 */
.btn {
    font-size: 1rem;
    padding: 1rem 2.5rem;
    border: none;
    outline: none;
    border-radius: 0.4rem;
    cursor: pointer;
    text-transform: uppercase;
    background-color: rgb(19, 28, 22);
    color: rgb(234, 234, 234);
    font-weight: 700;
    transition: 0.6s;
    box-shadow: 0px 0px 60px #45634d;
    /* -webkit-box-reflect: below 10px linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)); */
}

.btn:active {
    scale: 0.92;
}

.btn:hover {
    background: rgb(12, 78, 2);
    background: linear-gradient(270deg, rgba(2, 78, 27, 0.681) 0%, rgba(71, 232, 31, 0.873) 60%);
    color: rgb(4, 4, 38);
}
.btn.red {
    background: rgb(12, 78, 2);
    background: linear-gradient(270deg, rgba(78, 2, 2, 0.681) 0%, rgba(255, 5, 5, 0.873) 60%);
    color: white;
}
.btn.red:hover {
    background: rgb(12, 78, 2);
    background: linear-gradient(270deg, rgba(255, 4, 4, 0.681) 0%, rgba(252, 138, 138, 0.873) 60%);
    color: black;
}
.btn.blue {
    background: rgb(12, 78, 2);
    background: linear-gradient(270deg, rgba(78, 2, 11, 0.681) 0%, rgba(49, 81, 222, 0.873) 60%);
    color: white;
}
.btn.blue:hover {
    background: rgb(12, 78, 2);
    background: linear-gradient(270deg, rgba(49, 81, 222, 0.681) 0%, rgba(162, 179, 255, 0.873) 60%);
    color: black;
}

.btn.reflect {
    -webkit-box-reflect: below 10px linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.button.nuke {
    color: var(--green-nuke);
}
.button.blue {
    color: var(--blue);
}

.nav-item:hover {
    background-image: radial-gradient(circle, rgba(2, 78, 27, 0.05) 0%, rgba(71, 232, 31, 0.2) 100%);
}

.project-card-wrapper {
    border-radius: var(--app-border);
    width: 100%;
    display: flex;
    flex-direction: column;
    scale: 1;
}

.project-card-wrapper:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    scale: 1.03;
    transition: all 0.2s ease-in-out;
}

.page-clamp {
    width: clamp(300px, 95%, 1280px) !important;
    margin: 0 auto;
}

.projects-cols {
    /* columns: 3 240px; */
    /* gap: 12px; */
    row-gap: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: masonry;
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}
